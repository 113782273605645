import React, { useEffect, useState } from "react";
import _ from 'lodash';
import {
    collection,
    getDocs,
    orderBy,
    query,
    getCountFromServer
} from 'firebase/firestore';

import Table from "../components/Table";

import '../css/ListCommon.css';
import { useNavigate } from "react-router-dom";

const Notice = ({db, user, isLoggedIn, dirSet, postSet}) => {


    const ITEM_LIMIT = 8;
    // const DIR = "test";
    const DIR = "notice";

    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [len, setLen] = useState(0);
    const [docSnapshots, setDocSnapshots] = useState();

    const noticeRef = collection(db, DIR);

    const navigate = useNavigate();

    useEffect(() => {
        dirSet(DIR);
    }, [])

    useEffect(() => {
        const getCLength = async () => {
            const lengthSnapshot = await getCountFromServer(noticeRef);
            setLen(lengthSnapshot.data().count);
            setPagesCnt();
        }
        getCLength();
    }, [len]);

    useEffect(() => {
        const fetchData = async () => {
            const notices = query(noticeRef, orderBy("id", "desc"));
            setDocSnapshots(await getDocs(notices));
        }
        fetchData();
    }, []);

    useEffect(() => {
        const setItemList = () => {
            if(docSnapshots !== undefined) {
                setList(docSnapshots.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data()
                })));
            }
        }
        setItemList();
    }, [docSnapshots]);

    const setPagesCnt = () => {
        if(len === 0) {
            setPages(0);
        } else if(len%ITEM_LIMIT === 0) {
            setPages(parseInt(len/ITEM_LIMIT));
        } else {
            setPages(parseInt(len/ITEM_LIMIT) + 1);
        }
    }

    const getTable = () => {
        if(list.length > 0) {
            return (
                <Table data={list} handler={postSet} />
            );
        } else {
            return (
                <div className="noPosts">등록된 게시글이 없습니다.</div>
            );
        }
    }

    const currpageHandler = (page) => {
        setCurrPage(page);
    }

    const pagination = (currPage, items, pages) => {
        const currIndex = (currPage - 1) * pages;
        const arr = _(items).slice(currIndex, currIndex+pages).value();
        return arr;
    }

    const getPagedData = () => {
        const paginateData = pagination(currPage, list, pages);
        return {data: paginateData, count: list.length};
    }

    const newPost = () => {
        postSet(undefined);
        navigate("/edit");
    }

    const postBtn = () => {
        if(isLoggedIn) {
            return (
                <div className="btnNewPostBox">
                    {<button className="btnNewPost" onClick={() => newPost()}>글쓰기</button>}
                </div>
            );
        }
        return null;
    }

    return (
        <section className="noticeSection listSections">
            <div className="pageBg">
                <img src={process.env.PUBLIC_URL + "images/bg-page.jpg"} alt="notice-list" id="pageBg" />
            </div>
            <div className="noticeContainer containers">
                <div className="noticeTitle listTitle">
                    <p className="noticeTitleText listTitleText">공지사항</p>
                </div>
                <div className="noticeContents listContents">
                    {getTable()}
                </div>
                {postBtn()}
                {/* <div className="btnNewPostBox">
                    <button className="btnNewPost">글쓰기</button>
                </div> */}
            </div>
        </section>
    );
}

export default Notice;