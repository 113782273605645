import React from "react";

import styles from '../../css/Handal/Intro.module.css';

const HandalIntro = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="hIntroBg">
                <div className={styles.introBgBox}>
                    <img src={process.env.PUBLIC_URL + "images/handal/introBg.png"} alt="도고온천마을" className={styles.introBgImg} />
                </div>
            </div>
            <div className={["containers", styles.introContainer].join(' ')}>
                <div className={styles.introContents}>
                    <h2 className={styles.emphasize}>도고온천 마을살이</h2>
                </div>
            </div>
        </section>
    );
}

export default HandalIntro;