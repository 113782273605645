import './App.css';
import {apiKey, auth, firestore} from './FirebaseConfig';
import { BrowserView, MobileView } from 'react-device-detect';
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LoginPage from './pages/Login';
import Notice from './pages/Notice';
import Archive from './pages/Archive';
import Board from './pages/Board';

import EditPage from './pages/EditPage';
import Header from './common/Header';
import { signOut } from 'firebase/auth';
import Home from './pages/Home';
import Village from './pages/Village';
import Handal from './pages/Handal';

import Loading from './common/Loading';
import Mobile from './pages/Mobile';

function App() {

    const [userObj, setUserObj] = useState({});
    const [docDir, setDocDir] = useState("notice");  // "notice/" or "archive/"
    const [post, setPost] = useState();
    const [menuToggle, setMenuToggle] = useState(false);
    const navigate = useNavigate();

    const menuToggleHandler = () => {
      menuToggle ? setMenuToggle(false) : setMenuToggle(true);
    }

    const loginHandler = (user) => {
        setUserObj(user);
        navigate("/");
    }

    const logoutHandler = () => {
        if(window.confirm("로그아웃하시겠습니까?")) {
            menuToggleHandler();
            signOutFun();
        } else {
            
        }
    }

    const signOutFun = async () => {
      await signOut(auth);
      setUserObj({});
      setMenuToggle(false);
      navigate("/");
    }

    const setDirHandler = (dir) => {
      setDocDir(dir);
    }

    const setPostHandler = (item) => {
        setPost(item);
        if(item !== undefined) {
          navigate("/board");
        }
    }

    const _session_key = `firebase:authUser:${apiKey}:[DEFAULT]`;
    const isLoggedIn = sessionStorage.getItem(_session_key) ? true : false;

    // useEffect(() => {
    //   console.log(userObj);
    // }, [post])

    return (
        <>
          <MobileView>
            <Mobile />
          </MobileView>

          <BrowserView>
            {/* <Loading /> */}

            <Header user={userObj} isLoggedIn={isLoggedIn} menuToggle={menuToggle} menuToggler={menuToggleHandler} logoutHandler={logoutHandler} />
            
            <Routes>
              <Route exact="exact" path='/' element={<Home/>} />
              <Route path='/intro' element={<Village/>} />
              <Route path='/handal' element={<Handal/>} />
              <Route path='/notice' element={<Notice db={firestore} isLoggedIn={isLoggedIn} user={userObj} dirSet={setDirHandler} postSet={setPostHandler} />}/> 
              <Route path='/archive' element={<Archive db={firestore} isLoggedIn={isLoggedIn} user={userObj} dirSet={setDirHandler} postSet={setPostHandler} />}/>
              <Route exact="exact" path='/login' element={<LoginPage loginHandler={loginHandler} />}/>
              <Route path='/edit' element={<EditPage db={firestore} isLoggedIn={isLoggedIn} user={userObj} dir={docDir} post={post} />}/>
              <Route path='/board' element={<Board db={firestore} user={userObj} isLoggedIn={isLoggedIn} dir={docDir} post={post} />} />
            </Routes>
          </BrowserView>
        </>
    );
}

export default App;
