import React from "react";

import '../../css/Handal/HandalCommon.css';

const Live = () => {

    return (
        <section className="sections">
            <div className="containers handalContainer">
                <div className="handalContents">
                    <div className="handalTitleBox">
                        <h2>함께 살아가고</h2>
                    </div>
                    <div className="handalRow">
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live0.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live1.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live2.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                    </div>
                    <div className="handalRow">
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live3.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live4.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/live5.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Live;