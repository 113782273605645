import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import NavItem from "../components/NavItem";

import '../css/Header.css';

const Header = ({user, menuToggle, menuToggler, isLoggedIn, logoutHandler}) => {

    const [menuItems, setMenuItems] = useState([
        {
            name: "intro",
            address: "/intro",
            text: "DOGO 온천 마을 소개"
        }, {
            name: "handal",
            address: "/handal",
            text: "마을살이"
        }, {
            name: "notice",
            address: "/notice",
            text: "공지사항"
        }, {
            name: "archive",
            address: "/archive",
            text: "기록"
        }, {
            name: "login",
            address: "/login",
            text: "관리"
        }
    ]);

    useEffect(() => {
        // if(Object.keys(user).length === 0) {
        if (!isLoggedIn) {
            setMenuItems([
                {
                    name: "intro",
                    address: "/intro",
                    text: "DOGO 온천 마을 소개"
                }, {
                    name: "handal",
                    address: "/handal",
                    text: "마을살이"
                }, {
                    name: "notice",
                    address: "/notice",
                    text: "공지사항"
                }, {
                    name: "archive",
                    address: "/archive",
                    text: "기록"
                }, {
                    name: "login",
                    address: "/login",
                    text: "관리"
                }
            ])
        } else {
            setMenuItems([
                {
                    name: "intro",
                    address: "/intro",
                    text: "DOGO 온천 마을 소개"
                }, {
                    name: "handal",
                    address: "/handal",
                    text: "마을살이"
                }, {
                    name: "notice",
                    address: "/notice",
                    text: "공지사항"
                }, {
                    name: "archive",
                    address: "/archive",
                    text: "기록"
                }, {
                    name: "logout",
                    text: "로그아웃",
                    logoutHandler: logoutHandler
                }
            ])
        }
    }, [user])

    return (
        // <nav className="navbar" style={Object.keys(user).length === 0 ? {display:
        // "none"} : {}} id='top-navbar'>
        <nav className="navbar" id='top-navbar'>
            <div className="navbar-logo">
                <Link to="/">
                    <img
                        src={process.env.PUBLIC_URL + '/favicons/logo192.png'}
                        alt="logo"
                        id='logo'/>
                </Link>
            </div>
            <div className="menu-box">
                <div
                    className={!menuToggle
                        ? "burger__menu"
                        : "x__menu"}
                    onClick={() => menuToggler()}>
                    <div className="burger_line1"></div>
                    <div className="burger_line2"></div>
                    <div className="burger_line3"></div>
                </div>
                <div className={["menu-list-box", !menuToggle ? "menu-list-hidden" : "menu-list-visible"].join(" ")}>
                    <div className="menu-list">
                        {
                            menuItems.map((item) => {
                                return (
                                    <NavItem
                                        itemName={item.name}
                                        addr={item.address}
                                        key={item.name}
                                        item={item.text}
                                        menuToggle={menuToggler}
                                        logoutHandler={item.logoutHandler}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;