import React from "react";

import styles from '../../css/Village/Plan.module.css';

const Plan = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="planBg"></div>
            <div className={["containers", styles.planContainer].join(' ')}>
                <div className={styles.planTitle}>
                    <h2 className={styles.emphasize}>"식물, 동물 그리고 사람이 행복한 DOGO 온천 마을"</h2>
                </div>
                <div className={styles.planContents}>
                    <div className={styles.planLeft}>
                        <div className={styles.planImgBox}>
                            <img src={process.env.PUBLIC_URL + "images/village/plan_plant.jpg"} alt="도고온천마을" className={styles.planImg} />
                        </div>
                        <div className={styles.planDesc}>
                            <p className={styles.descTitle}>1년차</p>
                            <p className={styles.descCategory}>식물</p>
                            <p className={styles.descContent}>원예, 농업</p>
                        </div>
                    </div>
                    <div className={styles.planCenter}>
                        <div className={styles.planImgBox}>
                            <img src={process.env.PUBLIC_URL + "images/village/plan_animal.jpg"} alt="도고온천마을" className={styles.planImg} />
                        </div>
                        <div className={styles.planDesc}>
                            <p className={styles.descTitle}>2년차</p>
                            <p className={styles.descCategory}>동물</p>
                            <p className={styles.descContent}>유기동물, 반려동물 사업 <br /> 동행 리빙랩</p>
                        </div>
                    </div>
                    <div className={styles.planLeft}>
                        <div className={styles.planImgBox}>
                            <img src={process.env.PUBLIC_URL + "images/village/plan_people.jpg"} alt="도고온천마을" className={styles.planImg} />
                        </div>
                        <div className={styles.planDesc}>
                            <p className={styles.descTitle}>1~3년차</p>
                            <p className={styles.descCategory}>공동체</p>
                            <p className={styles.descContent}>창업, 공유공간 거리조성</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Plan;