import React from "react";

import styles from '../../css/Village/Salon.module.css';

const Salon = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="vIntroBg">
                <div className={styles.salonBgBox}>
                    <img
                        src={process.env.PUBLIC_URL + "images/village/salon_bg.jpg"}
                        alt="도고온천마을"
                        className={styles.salonBgImg}/>
                </div>
            </div>
            <div
                className={["containers", styles
                    .salonContainer]
                    .join(' ')}>
                <div className={styles.salonContents}>
                    <h2 className={styles.salonTitle}>도고살롱</h2>
                    <div className={styles.salonDesc}>
                        <p>도고상사 안에 있는 바 형태의 살롱.</p>
                        <p className={styles.emphasize}>퇴근 후 한 잔?</p>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Salon;