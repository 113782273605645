import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const NavItem = ({itemName, addr, menuToggle, item, logoutHandler}) => {

    return (
        <>
            {
                addr === undefined
                ? 
                <span className={[
                    "menu-item",
                    ["menu-item", itemName]
                    .join("-")
                    ]
                    .join(" ")} onClick={() => logoutHandler()}>{item}</span>
                :
                <Link to={addr} className={[
                    "menu-item",
                    ["menu-item", itemName]
                    .join("-")
                    ]
                    .join(" ")} onClick={() => menuToggle()}>{item}</Link>
            }
        </>
        );
}

export default NavItem;