import React from "react";

import styles from '../css/Notice.module.css';

const Table = ({data, handler}) => {

    const getData = () => {
        return (
            data.map((item) => {
                return (
                <tr key={item.id} className={styles.noticeTableRow} onClick={() => handler(item)}>
                    <td className={styles.noticeTableData}>{item.title}</td>
                    <td className={styles.noticeTableData}>{item.writer}</td>
                </tr>
                );
            })
        );
    }

    return (
        <table id={styles.noticeTable}>
            <thead id={styles.noticeTableHeader}>
                <tr>
                    <th>제목</th>
                    <th>작성자</th>
                </tr>
            </thead>
            <tbody id={styles.noticeTableBody}>
                {getData()}
            </tbody>
        </table>
    );
}

export default Table;