import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, listAll, ref } from "firebase/storage";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import '../css/Board.css';
import { storage } from "../FirebaseConfig";

const Board = ({db, user, isLoggedIn, dir, post}) => {

    const navigate = useNavigate();

    // useEffect(() => {
    //     if(Object.keys(user).length === 0) {
    //         if(!alert("잘못된 접근입니다.")) {
    //             window.open("https://dogodogo.co.kr/", "_self");
    //         }
    //     }
    // }, [])

    const deleteHandler = async () => {
        if(window.confirm("정말 삭제하시겠습니까?")) {
            const listRef = ref(storage, dir + "/" + post.id + "/");
            listAll(listRef).then((res) => {
                res.items.forEach((itemRef) => {
                    deleteObject(itemRef).then(() => {
                        console.log("delete complete");
                    }).catch((e) => {
                        console.error(e.message);
                    })
                })
            })
            await deleteDoc(doc(db, dir, post.id));
            navigate("/" + dir);
        }
    }

    const updateHandler = () => {
        navigate("/edit");
    }

    const getBoardTitle = () => {
        try {
            return(<p className="boardTitleText">{post.title}</p>);
        } catch (error) {
            if(!alert("잘못된 접근입니다.")) {
                // window.open("https://dogodogo.co.kr/", "_self");
                navigate("/");
            }
        }
    }

    const getBoardContent = () => {
        try {
            return(
                <div className="boardContentsText" >
                    <CKEditor
                        className="boardEditor"
                        name="editor"
                        editor={ClassicEditor}
                        data={post.content}
                        disabled={true} />
                </div>
            );
        } catch (error) {
            if(!alert("잘못된 접근입니다.")) {
                // window.open("https://dogodogo.co.kr/", "_self");
                navigate("/");
            }
        }
    }

    const boardBtns = () => {
        if(isLoggedIn) {
            return (
                <div className="boardButtons">
                    <button className="boardBtns btnBoardUpdate" onClick={() => updateHandler()}>수정</button>
                    <button className="boardBtns btnBoardDelete" onClick={() => deleteHandler()}>삭제</button>
                </div>
            );
        }
        return null;
    }

    return (
        <section className="boardSection">
            <div className="boardContainer containers">
                <div className="boardTitle">
                    {getBoardTitle()}
                </div>
                <div className="boardContents">
                    {getBoardContent()}
                </div>
                {boardBtns()}
                {/* <div className="boardButtons">
                    <button className="boardBtns btnBoardUpdate">수정</button>
                    <button className="boardBtns btnBoardDelete">삭제</button>
                </div> */}
            </div>
        </section>
    );

}

export default Board;