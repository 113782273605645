import React from "react";
import Apply from "../components/Handal/Apply";
import Exp from "../components/Handal/Exp";
import HandalIntro from "../components/Handal/Intro";
import Live from "../components/Handal/Live";
import Make from "../components/Handal/Make";

const Handal = () => {

    return (
        <main className="mainContainer" id="handal">
            <HandalIntro />
            <Exp />
            <Live />
            <Make />
            <Apply />
        </main>
    );

}

export default Handal;