import React from "react";

import styles from '../../css/Village/Character.module.css';

const Character = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id={styles.charBg}>
                <div className={styles.charBgBox}>
                    <img src={process.env.PUBLIC_URL + "images/village/logos.png"} alt="도고온천마을" className={styles.charBgImg} />
                </div>
            </div>
            <div className={["containers", styles.charContainer].join(' ')}>
                <div className={styles.charContents}>
                    <img src={process.env.PUBLIC_URL + "images/village/dodudge_intro.png"} alt="도고온천마을" className={styles.dodudge} />
                </div>
            </div>
        </section>
    );

}

export default Character;