import React, { useEffect, useState } from "react";
import {
    collection,
    getDocs,
    orderBy,
    query,
    getCountFromServer
} from 'firebase/firestore';
import { useNavigate } from "react-router-dom";

import '../css/ListCommon.css';
import Cards from "../components/Card";

const Archive = ({db, user, isLoggedIn, dirSet, postSet}) => {

    const ITEM_LIMIT = 8;
    // const DIR = "test";
    const DIR = "archive";

    const [list, setList] = useState([]);
    const [pages, setPages] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [len, setLen] = useState(0);
    const [docSnapshots, setDocSnapshots] = useState();

    const archiveRef = collection(db, DIR);

    const navigate = useNavigate();

    useEffect(() => {
        dirSet(DIR);
    })

    useEffect(() => {
        const getCLength = async () => {
            const lengthSnapshot = await getCountFromServer(archiveRef);
            setLen(lengthSnapshot.data().count);
            setPagesCnt();
        }
        getCLength();
    }, [len]);

    useEffect(() => {
        const fetchData = async () => {
            const archives = query(archiveRef, orderBy("id", "desc"));
            setDocSnapshots(await getDocs(archives));
        }
        fetchData();
    }, []);

    useEffect(() => {
        const setItemList = () => {
            if(docSnapshots !== undefined) {
                setList(docSnapshots.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data()
                })));
            }
        }
        setItemList();
    }, [docSnapshots]);

    const setPagesCnt = () => {
        if(len === 0) {
            setPages(0);
        } else if(len%ITEM_LIMIT === 0) {
            setPages(parseInt(len/ITEM_LIMIT));
        } else {
            setPages(parseInt(len/ITEM_LIMIT) + 1);
        }
    }

    const getCards = () => {
        
        if(list.length > 0) {
            return (
                <Cards data={list} handler={postSet} />
            );
        } else {
            return (
                <div className="noPosts">등록된 게시글이 없습니다.</div>
            );
        }
    }

    const newPost = () => {
        postSet(undefined);
        navigate("/edit");
    }
    
    const postBtn = () => {
        if(isLoggedIn) {
            return (
                <div className="btnNewPostBox">
                    {<button className="btnNewPost" onClick={() => newPost()}>글쓰기</button>}
                </div>
            );
        }
        return null;
    }


    return (
        <section className="archiveSection listSections">
            <div className="pageBg">
                <img src={process.env.PUBLIC_URL + "images/bg-page.jpg"} alt="archive-list" id="pageBg" />
            </div>
            <div className="archiveContainer containers">
                <div className="archiveTitle listTitle">
                    <p className="archiveTitleText listTitleText">기록</p>
                </div>
                <div className="archiveContents listContents">
                    {getCards()}
                </div>
                {postBtn()}
            </div>
        </section>
    );
}

export default Archive;