import React from "react";

import styles from '../css/Card.module.css';

const Cards = ({data, handler}) => {

    const getData = () => {
        console.log(data);
        return (
            data.map((item) => {
                return (
                    <div key={item.id} className={styles.cardDiv} onClick={() => handler(item)}>
                        <div className={styles.card}>
                            <div className={styles.cardThumbnailBox}>
                                <img src={item.thumbnail} id={styles.cardThumbnail} alt="기록" />
                            </div>
                            <div className={styles.cardTitleBox}>
                                <p className={styles.cardTitle}>{item.title}</p>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }

    return (
        <div className={styles.archiveCards}>
            {getData()}
        </div>
    );

}

export default Cards;