import React, { useEffect, useState } from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {storage} from "../FirebaseConfig";
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {useNavigate} from 'react-router-dom';

import '../css/EditPage.css';

const EditPage = ({db, user, isLoggedIn, dir, post}) => {


    /*********************************
     * 
     * db, dir for test
     * 
     ********************************* */
    // db = firestore;
    // dir = "test";
    // post = undefined;

    const [postId, setPostId] = useState("");
    const [postTitle, setPostTitle] = useState("");
    const [uploadData, setUploadData] = useState({});
    const [thumbnail, setThumbnail] = useState("");

    const navigate = useNavigate();

    // useEffect(() => {
    //     if(Object.keys(user).length === 0) {
    //         if(!alert("잘못된 접근입니다.")) {
    //             window.open("https://dogodogo.co.kr/", "_self");
    //         }
    //     }
    // }, []);
    useEffect(() => {
        if(!isLoggedIn) {
            if(!alert("잘못된 접근입니다.")) {
                navigate("/");
            }
        }
    }, []);

    useEffect(() =>{
        if(post === undefined) {
            setPostId("" + Date.now());
        } else {
            setPostId(post.id);
            setPostTitle(post.title);
            setThumbnail(post.thumbnail);
            setUploadData(post.content);
        }
    }, [])

    useEffect(() => {
        if(dir === undefined) {
            alert("잘못된 접근입니다.");
            // window.open("https://dogodogo.co.kr/", "_self");
            navigate("/");
        } else {
            console.log(dir);
        }
    }, [])

    const getTitle = async () => {
        const docRef = doc(db, dir, post.id);
        try {
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()) {
                return docSnap.data().title;
            }
        } catch(e) {
            console.error("Error reading doc: ", e);
        }
    }

    const titleChangeHandler = (e) => {
        setPostTitle(e.currentTarget.value);
    }

    const thumbnailChangeHandler = (e) => {
        const name = dir + "/" + postId + "/thumbnail.jpg";
        const thumbnailRef = ref(storage, name);
        if(e.currentTarget.files[0].size > 5*1024*1024) {
            alert("5MB 이하의 이미지만 가능합니다.");
            e.currentTarget.value = null;
            return;
        } else {
            uploadBytes(thumbnailRef, e.currentTarget.files[0])
            .then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setThumbnail(""+url);
                })
            })
        }
    }

    const getContent = () => {
        return post.content;
    }

    const uploadDoc = async (e) => {

        e.preventDefault();

        // 기존 게시글일 때
        if(post !== undefined) {
                setPostId(post.id);
                try {
                    const docRef = await setDoc(doc(db, dir, post.id), {
                        id: post.id,
                        title: postTitle,
                        writer: uploadData.writer,
                        content: uploadData.content,
                        thumbnail: thumbnail,
                    });
                    const listRef = ref(storage, dir + "/" + postId + "/");
                    listAll(listRef).then((res) => {
                        res.items.forEach((itemRef) => {
                            const location = itemRef._location.path_;
                            const name = location.split('/')[location.split('/').length - 1];
                            const code = name.substring(0, name.length - 4);
    
                            const isContain = uploadData.content.includes(code);
    
                            if(!isContain) {
                                if(code !== 'thumbnail') {
                                    deleteObject(itemRef).then(() => {
                                    }).catch((e) => {
                                        console.error(e);
                                    });
                                }
                            }
                        });
                    });
                } catch (e) {
                    console.error("Error adding doc: ", e);
                } finally {
                    navigate("/" + dir);
                }
        }
        
        // 새로운 게시글일 때
        else {
            if(Object.keys(uploadData).length === 0 || uploadData.content === '') {
                alert("내용을 입력해주세요.");
            } 
            else {
                try {
                    const docRef = await setDoc(doc(db, dir, postId), {
                        id: postId,
                        title: postTitle,
                        writer: uploadData.writer,
                        content: uploadData.content,
                        thumbnail: thumbnail,
                    });
                    const listRef = ref(storage, dir + "/" + postId + "/");
                    listAll(listRef).then((res) => {
                        res.items.forEach((itemRef) => {
                            const location = itemRef._location.path_;
                            const name = location.split('/')[location.split('/').length - 1];
                            const code = name.substring(0, name.length - 4);

                            const isContain = uploadData.content.includes(code);

                            if(!isContain) {
                                if(code !== 'thumbnail') {
                                    deleteObject(itemRef).then(() => {
                                    }).catch((e) => {
                                        console.error(e);
                                    });
                                }
                            }
                        });
                    });
                } catch (e) {
                    console.error("Error adding doc: ", e);
                } finally {
                    console.log(dir);
                    navigate("/" + dir);
                }
            }
        }
    }

    const cancelHandler = (e) => {
        navigate(-1);
    }

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }
        // Starts the upload process.
        upload() {
            let now = Date.now();
            let imgName = dir + "/" + postId + "/" + now + '.jpg';

            return this
                .loader
                .file
                .then((file) => 
                    new Promise((resolve, reject) => {
                            uploadBytes(ref(storage, imgName), file)
                                .then((snapshot) => {
                                    return getDownloadURL(snapshot.ref);
                                })
                                .then((downloadURL) => {
                                    resolve({default: downloadURL});
                                })
                                .catch((error) => {
                                    reject(error.message);
                                })
                    })
                );
        }
    }

    return (
        <section className="editSection">
            <form className="containers editContainer" onSubmit={uploadDoc}>
                <div className="titleBox">
                    <input type="text" className="inputTitle" id="inputTitle" name="title" placeholder="제목:" defaultValue={post !== undefined ? post.title : ""} onChange={titleChangeHandler} required />
                </div>
                <div className="thumbnailBox">
                    <label htmlFor="thumbnailUpload">썸네일: </label>
                    <input type="file" accept="image/*" id="thumbnailUpload" onChange={thumbnailChangeHandler} />
                </div>
                <div className="editorBox">
                    <CKEditor
                        name="editor"
                        editor={ClassicEditor}
                        data={post !== undefined ? post.content : ""}
                        onReady={
                            editor => {
                                editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                    return new MyUploadAdapter(loader);
                            };
                        }}
                        onChange={
                            (e, editor) => {
                                const data = {
                                    writer: "admin",
                                    content: editor.getData()
                                };
                                setUploadData(data);
                            }
                        } />
                </div>
                <div className="buttonBox">
                    <button type="submit" className="btnEditSubmit editBtns">저장</button>
                    <button type="button" className="btnEditCancel editBtns" onClick={() => cancelHandler()}>취소</button>
                </div>
            </form>
        </section>
    )
}

export default EditPage;