import React from "react";
import Character from "../components/Village/Character";
import Plan from "../components/Village/Plan";
import Salon from "../components/Village/Salon";
import Sangsa from "../components/Village/Sangsa";
import VillageIntro from "../components/Village/VIntro";

const Village = () => {

    return (
        <main className="mainContainer" id="village">
            <VillageIntro />
            <Character />
            <Plan />
            <Sangsa />
            <Salon />
        </main>
    );

}

export default Village;