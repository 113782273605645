import React from "react";

import styles from '../../css/Home/Social.module.css';

const SocialLinks = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="socialBg">
                <div className={styles.socialBgBox}>
                    <img src={process.env.PUBLIC_URL + "images/home/sns_bg.jpg"} alt="도고온천마을" className={styles.socialBgImg} />
                </div>
            </div>
            <div className={["containers", styles.socialContainer].join(' ')}>
                <div className={styles.socialRow}>
                    <div className={styles.socialLeft}>
                        <div className={styles.socialTitle}>
                            <p className={styles.socialTitleText}>도고온천마을 Contact</p>
                        </div>
                        <div className={styles.socialInsta}>
                            <a href="https://www.instagram.com/dog0on1000" className={styles.socialSymbols} target="_blank" rel="noopenner noreferrer">
                                <img src={process.env.PUBLIC_URL + "images/home/dogo_insta.png"} alt="도고온천마을" className={styles.socialImg} />
                            </a>
                        </div>
                        <div className={styles.socialOther}>
                            <a href="https://www.youtube.com/c/%EB%8F%84%EA%B3%A0ONAIR/featured" className={styles.socialSymbols} target="_blank" rel="noopenner noreferrer">
                                <img src={process.env.PUBLIC_URL + "images/home/dogo_onair.png"}  alt="도고온천마을" className={styles.socialImg} id={styles.onairImg} />
                            </a>
                        </div>
                    </div>
                    <div className={styles.socialRight}>
                        <div className={styles.socialTitle}>
                            <p className={styles.socialTitleText}>온어스 Contact</p>
                        </div>
                        <div className={styles.socialInsta}>
                            <a href="https://www.instagram.com/0nus_on_us" className={styles.socialSymbols} target="_blank" rel="noopenner noreferrer">
                                <img src={process.env.PUBLIC_URL + "images/home/onus_insta.png"} alt="도고온천마을" className={styles.socialImg} />
                            </a>
                        </div>
                        <div className={styles.socialOther}>
                            <div className={styles.socialSymbols}>
                                <img src={process.env.PUBLIC_URL + "images/home/onus_logo.png"} alt="도고온천마을" className={styles.socialImg} id={styles.onusLogo} />
                                <p>Email: onus0128@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default SocialLinks;