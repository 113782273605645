import React from "react";

import styles from '../css/Mobile.module.css';

const Mobile = () => {

    return (
        <main className="mainContainer" id="mobile">
            <div className="backgrounds" id={styles.mobileBg}>
                <div className={styles.mobileBgBox}>
                    <img
                        src={process.env.PUBLIC_URL + 'favicons/logo512.png'}
                        alt="도고온천마을"
                        className={styles.mobileBgImg}/>
                </div>
            </div>
            <div
                className={["containers", styles
                    .mobileContainer]
                    .join(' ')}>
                <div className={styles.mobileContents}>
                    <p>죄송합니다.</p>
                    <p>모바일 페이지는 준비중입니다.</p>
                    <p>PC버전을 이용해주세요.</p>
                </div>
            </div>
        </main>
    );

}

export default Mobile;