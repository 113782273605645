import React from "react";

import styles from '../../css/Village/VIntro.module.css';

const VillageIntro = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="vIntroBg">
                <div className={styles.introBgBox}>
                    <img src={process.env.PUBLIC_URL + "images/village/introBg.png"} alt="도고온천마을" className={styles.introBgImg} />
                </div>
            </div>
            <div className={["containers", styles.introContainer].join(' ')}>
                <div className={styles.introContents}>
                    <h2 className={styles.emphasize}>DOGO 온천 마을 소개</h2>
                </div>
            </div>
        </section>
    );

}

export default VillageIntro;