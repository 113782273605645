import React from "react";
import Slider from "react-slick";

import styles from '../../css/Village/Sangsa.module.css';

const Sangsa = () => {

    const carousel_settings = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplayspeed: 500,
        className: styles.sangsaSlide,
        arrows: false
    };

    return (
        <section className="sections sangsaSection">
            <div className="backgrounds" id="vIntroBg">
                <div className={styles.sangsaBgBox}>
                    <img
                        src={process.env.PUBLIC_URL + "images/village/sangsa_back.jpg"}
                        alt="도고상사"
                        className={styles.sangsaBgImg}/>
                </div>
            </div>
            <div
                className={["containers", styles
                    .sangsaContainer]
                    .join(' ')}>
                <div className={styles.sangsaTitle}>
                    <h2>도고상사</h2>
                </div>
                <div className={styles.sangsaContents}>
                    <div className={styles.sangsaLeft}>
                        <Slider {...carousel_settings}>
                            <div className={styles.carouselItem}>
                                <img
                                    src={process.env.PUBLIC_URL + "images/village/sangsa_front.jpg"}
                                    alt="도고상사"
                                    className={styles.sangsaLeftImg}/>
                            </div>
                            <div className={styles.carouselItem}>
                                <img
                                    src={process.env.PUBLIC_URL + "images/village/sangsa_inner.png"}
                                    alt="도고상사"
                                    className={styles.sangsaLeftImg}/>
                            </div>
                        </Slider>
                    </div>
                    <div className={styles.sangsaRight}>
                        <div className={styles.sangsaDesc}>
                            <p>
                                <span className={styles.emphasize}>함께 일하는 공유 오피스 “도고상사”</span>
                            </p>
                            <p>온어스가 운영하는 공간으로,</p>
                            <p>
                                <span className={styles.emphasize}>입주 기업</span>과
                                <span className={styles.emphasize}>멤버십 회원, 한 달 살이 프로그램 참여자</span>는<br/>자유롭게 이용 가능합니다.</p>
                            <p className={styles.referP}>* 테이블, 의자, 책상, 프린터, 냉장고 등이 준비되어 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sangsa;