import React from "react";

import styles from '../../css/Home/Slogun.module.css';

const Slogun = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="slogunBg">
                <div className={styles.slogunBgBox}>
                    <img src={process.env.PUBLIC_URL + "images/home/slogun_bg.jpg"} alt="도고온천마을" className={styles.slogunBgImg} />
                </div>
            </div>
            <div className={["containers", styles.slogunContainer].join(' ')}>
                <div className={styles.slogunContents}>
                    <p className={styles.slogunDesc}>
                        충남 아산시 <strong>도고면</strong>에 위치한 DOGO온천 마을은
                    </p>
                    <div className={styles.strong}>
                        <p>"따뜻한 사람들이 모여사는 온천마을"</p>
                        <p>"식물, 동물 그리고 사람이 행복한 마을"</p>
                    </div>
                    <p>두 가지 슬로건으로 청년마을을 함께 만들어 가고 있습니다.</p>
                </div>
            </div>
        </section>
    );

}

export default Slogun;