import React from "react";

import '../../css/Handal/HandalCommon.css';

const Exp = () => {

    return (
        <section className="sections">
            <div className="backgrounds" id="handalBg">
                <div className="handalPageBgBox">
                    <img src={process.env.PUBLIC_URL + "images/handal/pageBg.jpg"} alt="도고온천마을" className="handalPageBgImg" />
                </div>
            </div>
            <div className="containers handalContainer">
                <div className="handalContents">
                    <div className="handalTitleBox">
                        <h2>함께 경험하고</h2>
                    </div>
                    <div className="handalRow">
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp0.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp1.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp2.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                    </div>
                    <div className="handalRow">
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp3.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp4.png"} alt="도고온천마을" className="handalImg" />
                        </div>
                        <div className="handalImgBox" >
                            <img src={process.env.PUBLIC_URL + "images/handal/exp5.jpg"} alt="도고온천마을" className="handalImg" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Exp;