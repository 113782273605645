import React, {useState} from "react";
import {browserSessionPersistence, onAuthStateChanged, setPersistence, signInWithEmailAndPassword, signOut} from "@firebase/auth";
import {auth} from '../FirebaseConfig';
import {useEffect} from "react";

import styles from '../css/Login.module.css';
// import {useNavigate} from "react-router";

const LoginPage = ({loginHandler}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});

    let isLoggedIn = false;

    // const navigate = useNavigate();

    // const loginHandler = () => {
    //     navigate("/notice", {
    //         state: {
    //             isLoggedIn: isLoggedIn,
    //             user: user
    //         }
    //     })
    // }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // setIsLoggedIn(true);
                isLoggedIn = true;
                setUser(currentUser);
            } else {
                // setIsLoggedIn(false);
                isLoggedIn = false;
            }
        })
    }, [])

    const loginFun = () => {
        try {
            setPersistence(auth, browserSessionPersistence)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.

                const user = signInWithEmailAndPassword(auth, email, password);

                isLoggedIn = !isLoggedIn;
                if(isLoggedIn) {
                    user.then((u) => {
                        // console.log(u);
                        loginHandler(u);
                    })
                }
                return ;
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
            });

        } catch (error) {
            console.log(error);
            alert("일치하는 사용자가 없습니다.");
        }
    }

    return (
        <main className="mainContainer" id="login">
            <div className={["sections", styles.loginSection].join(" ")}>
                <div className={styles.loginBg}>
                    <img
                        src={process.env.PUBLIC_URL + "/favicons/logo512.png"}
                        alt="login"
                        id={styles.loginBgImg}/>
                </div>
                <div className={styles.loginForm}>
                    <h3 className={styles.loginTitle}>로그인</h3>
                    <div className={styles.emailBox}>
                        <label htmlFor="email">E-mail:</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className={styles.inputs}
                            placeholder="이메일을 입력하세요"
                            required="required"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.currentTarget.value);
                            }}/>
                    </div>
                    <div className={styles.pwBox}>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className={styles.inputs}
                            placeholder="비밀번호를 입력하세요"
                            required="required"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.currentTarget.value);
                            }}/>
                    </div>
                    <div className={styles.btnBox}>
                        <button id="loginBtn" className={styles.loginBtn} onClick={() => loginFun()}>login</button>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default LoginPage;