import React from "react";

import styles from '../../css/Handal/Apply.module.css';

const Apply = () => {

    return (
        <section className="sections" id="sectionApply">
            <div
                className={["containers", styles
                    .applyContainer]
                    .join(' ')}>
                <div className={styles.applyBox}>
                    <div className={styles.applyTitleBox}>
                        <p className={styles.applyTitle}>도고온천 마을살이</p>
                    </div>
                    <div className={styles.applyBtn}>
                        <a href="">
                            <p className={styles.applyBtnText}>신청하기</p>
                        </a>
                    </div>
                </div>
                <div className={styles.applyCharacterBox}>
                    <div className={styles.applyCharLeftBox}>
                        <img
                            src={process.env.PUBLIC_URL + "images/handal/applyLeft.png"}
                            alt="너도오면고"
                            id={styles.applyCharLeft}
                            className={styles.applyChars}/>
                    </div>
                    <div className={styles.applyCharRightBox}>
                        <img
                            src={process.env.PUBLIC_URL + "images/handal/applyRight.png"}
                            alt="도더지들"
                            id={styles.applyCharRight}
                            className={styles.applyChars}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Apply;