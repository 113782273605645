import React from "react";
import Slider from "react-slick";

import styles from '../../css/Home/Intro.module.css';

const HomeIntro = () => {

    const carousel_settings = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplayspeed: 500,
        arrows: false,
    };

    return (
        <section className={["sections", styles.sectionIntro].join(" ")}>
            <div className="backgrounds" id="introBg">
                <Slider {...carousel_settings}>
                    <div className={styles.carouselItem}>
                        <div className={styles.introBgBox}>
                            <img
                                src={process.env.PUBLIC_URL + "images/home/intro_bg0.jpg"}
                                alt="도고온천마을"
                                className={styles.introBgImg}/>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <div className={styles.introBgBox}>
                            <img
                                src={process.env.PUBLIC_URL + "images/home/intro_bg1.jpg"}
                                alt="도고온천마을"
                                className={styles.introBgImg}/>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <div className={styles.introBgBox}>
                            <img
                                src={process.env.PUBLIC_URL + "images/home/intro_bg2.jpg"}
                                alt="도고온천마을"
                                className={styles.introBgImg}/>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="containers containerIntro">
                <div className={styles.introMsg}>
                    <p className={styles.introMsgContent}>
                        From.
                        <span className={styles.introMsgStrong}>도고온천마을</span>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default HomeIntro;