import React from "react";
import HomeIntro from "../components/Home/Intro";
import Slogun from "../components/Home/Slogun";
import SocialLinks from "../components/Home/Social";

const Home = () => {

    return (
        <main className="mainContainer" id="home">
            <HomeIntro />
            <Slogun />
            <SocialLinks />
        </main>
    );

}

export default Home;